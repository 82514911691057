import React, { ReactElement, useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import './NavSlider.scss'
import RouteLink from '../RouteLink/RouteLink'

import headerMenuNav from '../../data/header-mobile-navigation.json'
import NavSliderContent from '../NavSliderContent/NavSliderContent'

import avidaIcon from '../../assets/svg/avida-logo.svg'

type LinkTypes = {
  url: string
}

type NavSliderQueryTypes = {
  japaneseSite?: LinkTypes
  reserveNow?: LinkTypes
  bookAnOfficeAppointment?: LinkTypes
  leasing?: LinkTypes
  otherBrandLinks?: {
    nodes: {
      name: string
      url: string
    }[]
  }
}

type NavSliderTypes = {
  isOpen: boolean
  onClose(): void
}

const NavSlider = ({ isOpen, onClose }: NavSliderTypes): ReactElement => {
  const {
    japaneseSite,
    bookAnOfficeAppointment,
    leasing,
  }: // otherBrandLinks,
  // reserveNow,
  NavSliderQueryTypes =
    useStaticQuery(graphql`
      query NavSliderQuery {
        japaneseSite: link(
          category: { eq: "external" }
          name: { eq: "japanese_site" }
        ) {
          ...LinkFields
        }
        reserveNow: link(
          category: { eq: "external" }
          name: { eq: "reserve_now" }
        ) {
          ...LinkFields
        }
        bookAnOfficeAppointment: link(
          category: { eq: "external" }
          name: { eq: "book_an_office_appointment" }
        ) {
          ...LinkFields
        }
        leasing: link(category: { eq: "external" }, name: { eq: "leasing" }) {
          ...LinkFields
        }
        otherBrandLinks: allLink(
          filter: {
            category: { eq: "external" }
            name: {
              in: [
                "ayala_land"
                "ayala_land_premiere"
                "alveo"
                "amaia"
                "bellavita"
              ]
            }
          }
        ) {
          nodes {
            name
            url: link
          }
        }
      }
    `) || {}

  const [isPropertiesSubMenu, setPropertiesSubMenu] = useState(false)
  const [isNewsAndBlogs, setNewsAndBlogs] = useState(false)
  const [isOpenedLanguage, setOpenedLanguage] = useState(false)
  const [navItems, setNavItems] = useState(headerMenuNav)
  // const [isOpenedBrand, setOpenedBrand] = useState(false)
  // const [brandLinks] = useState(
  //   otherBrandLinks?.nodes.map((brandLink) => ({
  //     name: brandLink.name.replace(/_/g, ' '),
  //     url: brandLink.url,
  //   })) || [],
  // )

  const MenuSubLinks: any = {
    Properties: [
      {
        name: 'Condominium',
        url: '/properties/',
      },
      {
        name: 'House & Lot',
        url: '/properties/',
      },
      {
        name: 'Office',
        url: '/properties/',
      },
    ],
    'News & Blogs': [
      {
        name: 'News',
        url: '/news-and-events/',
      },
      {
        name: 'Events',
        url: '/news-and-events/',
      },
      {
        name: 'Pursuit of Passion',
        url: '/pursuit-of-passion/',
      },
      {
        name: 'Awards & Recognition',
        url: '/news-and-events/',
      },
    ],
  }

  const subItems1 = [
    {
      name: 'Japanese',
      url: japaneseSite?.url || '',
    },
  ]

  useEffect(() => {
    const newItem = headerMenuNav.map((item: any) =>
      item.name === 'Leasing' ? { ...item, url: leasing?.url } : item,
    )

    setNavItems(newItem)
  }, [leasing])

  return (
    <>
      <div
        className={`open-${isOpen ? 'dim' : ''}`}
        onClick={onClose}
        onKeyDown={onClose}
        role="button"
        aria-label="open-dim"
        tabIndex={0}
      />
      <div className={`nav-slider slide-${isOpen ? 'in' : 'out'}`}>
        {!!isOpen && (
          <Helmet
            bodyAttributes={{
              class: 'nav-slider-open',
            }}
          />
        )}

        <div className={`glaze glaze-${isOpen ? 'in' : 'out'}`}>
          <div className="nav-content">
            <img src={avidaIcon} alt="avidaIcon" />
            <RouteLink to="/" onClick={onClose}>
              <p>Home</p>
            </RouteLink>

            <ul className="nav-content-list" data-testid="nav-list-container">
              {navItems.map((navItem) => {
                // eslint-disable-next-line no-prototype-builtins
                if (MenuSubLinks.hasOwnProperty(navItem.name)) {
                  return MenuSubLinks[navItem.name].length ? (
                    <div
                      key={navItem.name}
                      className="nav-content-list-section"
                    >
                      <NavSliderContent
                        navItem={navItem}
                        setOpened={
                          navItem.name === 'Properties'
                            ? setPropertiesSubMenu
                            : setNewsAndBlogs
                        }
                        isOpened={
                          navItem.name === 'Properties'
                            ? isPropertiesSubMenu
                            : isNewsAndBlogs
                        }
                        subItems={MenuSubLinks[navItem.name]}
                      />
                    </div>
                  ) : (
                    <></>
                  )
                }
                if (navItem.name.match(/English/gi)) {
                  return japaneseSite ? (
                    <div
                      key={navItem.name}
                      className="nav-content-list-section"
                    >
                      <NavSliderContent
                        navItem={navItem}
                        setOpened={setOpenedLanguage}
                        isOpened={isOpenedLanguage}
                        subItems={subItems1}
                      />
                    </div>
                  ) : (
                    <></>
                  )
                }
                return (
                  <li key={navItem.name} className="nav-content-list-content">
                    <Link to={`${navItem.url}`}>{navItem.name}</Link>
                  </li>
                )
              })}
            </ul>
            <button className="button-appointment" type="button">
              <a
                href={bookAnOfficeAppointment?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Book an Office Appointment
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default NavSlider
